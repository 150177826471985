var render, staticRenderFns
import script from "./Boolean.vue?vue&type=script&lang=tsx&"
export * from "./Boolean.vue?vue&type=script&lang=tsx&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QToggle});

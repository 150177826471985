
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import ComponentItemFormMixin from "./item-form.mixin";

@Component({
  components: {},
  mixins: [],
})
export default class BooleanFormBuilder extends Mixins(ComponentItemFormMixin) {
  render() {
    const { conf, value } = this;
    return (
      <div>
        <span class="text-caption text-bold text-grey-8 q-ml-md"> {`请选择${conf.label}:   `}</span>
        <q-toggle
          color="primary"
          type="boolean"
          dense
          class="q-ma-md"
          value={value || false}
          onInput={(val: any) => this.$emit("change", val)}
        />
      </div>
    );
  }
}
